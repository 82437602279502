<template>
  <form @submit.prevent="submit">
    <AtomInput
        name="Имя"
        v-model="form.first_name"
        :error="errors.first_name"
    />
    <AtomInput
        name="Фамилия"
        v-model="form.last_name"
        :error="errors.last_name"
    />
    <EmailInput
        v-model="form.email"
        :error="errors.email"
    />
    <div class="inputs-grid">
      <ContactInput
        v-model:type="form.contact_type"
        v-model="form.contact"
        :error-type="errors.contact_type"
        :error="errors.contact"
      />
    </div>
    <MentorContactNotice />
    <PasswordAtomInput
        name="Придумайте пароль"
        v-model="form.password"
        :error="errors.password"
        desc="Буквы, цифры, не менее 8 символов"
    />
    <PasswordAtomInput
        name="Подтвердите пароль"
        v-model="form.password_confirmation"
        :error="errors.password_confirmation"
    />
    <BaseButton big color-primary :class="{ pending: loading }">Создать аккаунт</BaseButton>
    <UserAgreementNotice />
    <slot />
  </form>
</template>

<script setup>
import useForm from '~/composables/useForm'
import { register } from '~/api/auth'
import contactTypes from '~/data/contactTypes'

const router = useRouter()

const {
  form,
  errors,
  submit,
  loading
} = useForm(register, async () => {
  localStorage.setItem('registerEmail', form.value.email)
  await router.replace({ name: 'auth-confirm' })
})
form.value.type = 'user'
form.value.contact_type = contactTypes[0].id

const referral = useLocalStorage('referral', null)
watch(referral, value => value && (form.value.referral = value), { immediate: true })
</script>

<style scoped lang="scss">
form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
